
import { useThemeContext } from "../../../hooks/usethemeContext";
import { switchTheme } from '../../../assets/styles';
import { PopupWidget } from "react-calendly";

const CalendlyPopUp = () => {
    // define style by darkMode
    const { darkMode } = useThemeContext();
    const { buttonColors } = switchTheme(darkMode);

    return <>
        <PopupWidget
            // prefill?: Prefill;
            // utm?: Utm;
            // LoadingSpinner?: LoadingSpinner;
            branding={false}
            color={darkMode ? buttonColors.pinkish : buttonColors.altBlue}
            iframeTitle="Calendly Scheduling Page"
            pageSettings={{
                // backgroundColor: 'red',
                hideEventTypeDetails: false,
                hideGdprBanner: true,
                hideLandingPageDetails: false,
                // primaryColor: '00a2ff',
                // textColor: '4d5055'
            }}
            rootElement={document.getElementById("root")}
            text="Book An Appointment!"
            // textColor={buttonColors.teal}
            textColor='whitesmoke'
            // textColor='#1c1c1d'
            url={process.env.REACT_APP_CALENDLY}
        />
    </>;
}
 
export default CalendlyPopUp;
