
import { useEffect, useState } from 'react';

// to store the state to local storage
export default function usePersistedState(key, defaultValue) {
  
  const [state, setState] = useState(() => {
    return JSON.parse(localStorage.getItem(key)) || defaultValue;
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));

    if(JSON.stringify(state) === 'true') {
      document.getElementById('root').setAttribute('class', 'bg-dark text-light');
      document.getElementById('body').setAttribute('class', 'bg-dark text-light');
    } else {
      // document.getElementById('body').setAttribute('class', 'bg-light text-dark');
      document.getElementById('root').setAttribute('class', 'bg-light text-dark');
    }
  }, [key, state]);

  return [state, setState];
}