
import { ThemeProvider } from './hooks/usethemeContext';
import NavBar from './components/layout/Navbar';
import Home from './components/Home';
import BusinessOpportunities from './components/BusinessOpportunities';
import AboutUs from './components/sub-components/AboutUs';
import Contact from './components/Contact';
import Confirmation from './components/Confirmation';
import Redirect from './components/Redirect';
import Footer from './components/layout/Footer';
import CalendlyPopUp from './components/sub-components/buttons/CalendlyPopUp';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';

function App() {
  return (
    <>
      <Router>
        <ThemeProvider>
          <NavBar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/business-opportunities" element={<BusinessOpportunities />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/confirmation" element={<Confirmation />} />
            <Route path="*" element={<Redirect />} />
          </Routes>
          <Footer />
          <CalendlyPopUp />
        </ThemeProvider>
      </Router>
    </>
  );
}

export default App;
