
import { useThemeContext } from "../../hooks/usethemeContext";
import { switchTheme } from '../../assets/styles';
import { Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import about1 from '../../assets/images/about-1.webp';
// import about2 from '../../assets/images/about-2.webp';


const AboutUs = () => {
	// define style by darkMode
	const { darkMode } = useThemeContext();
	const { mainBannerTextColor } = switchTheme(darkMode);

	// const style = {color: '#0e65d7'};
	// const sm = '12', lg = '6'; // setting for 2 people about page
	const styles = { margin:  "auto", width:'15%'};
	const space = 3;

    return (
      <>
        <div className={`about-area mt-5 pb-${space}`}>
			<div className={`text-center pb-${space} border-bottom-0`}>
				<h1 className={mainBannerTextColor}>
					Who We Are!
				</h1>
			</div>
			<Container className="justify-items-center justify">
				<Row className="justify-items-center">
					<Col> {/* setting for 1 person about page */}
					{/* <Col sm={sm} lg={lg}> */} {/* setting for 2 people about page */}
						<Row>
							<div className={`pt-${space}`}>
								<img
									className={`rounded-${space} d-block mb-${space}`}
									style={styles}
									src={about1}
									alt="First slide"
								/>
								<h4 className={`text-center ${mainBannerTextColor}`}>
									Hi I'm Dominique!
								</h4>
								<p>
									Hi my name is Dominique Phillips, Owner & Founder of DW Consultant Group and DW Tax Software Company.I had a vision to create a company that could simplify the complex world of taxes for individuals and businesses alike. With over 7 years of experience in the financial industry, I'm driven by a desire to help fellow business owners achieve financial success.
								</p>
								<p>
									Starting a business saved my life and all was inspired by my children. While I have always worked in the world of finance, the pandemic gave me the push I needed to start working for myself. During this time, jobs were very scarce, pay was horrible, and home schooling was my only option. I had to think of a business that would allow me to have flexibility, services that everybody needed, and great income. Becoming a tax preparer was the first business to come to mind and it has been a blessing ever since I jumped into the industry. 
								</p>
								<p>
									'DW' has a lot of meaning to it and is always a constant reminder of my vision. Beyond my core team, DW Tax Software is a tight-knit family of like-minded professionals who share a common goal: your financial success!
								</p>
							</div>
						</Row>
					</Col>
					{/* <Col sm={sm} lg={lg}>
						<Row>
									<div className={`pt-${space}`}>
									<h4 className={`text-center ${mainBannerTextColor}`}>
										Jose
									</h4>
								<img
									className={`rounded-${space} d-block mb-${space}`}
									style={styles}
									src={about2}
									alt="First slide"
								/>
								<p>
									lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
								</p>
								<p>
									lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
								</p>
								<p>
									lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
								</p>
								<p>
									lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
								</p>
							</div>
						</Row>
					</Col> */}
						<Container className="text-center">
							{/* <h5 className={`text-center ${mainBannerTextColor}`}>
								Our Commitment to You!
							</h5> */}
							{/* <p>
								Our goal is to change people's lives by providing the best services and opportunities to Tax Companies and Tax Preparers. We do business fairly and we don't keep your hard earned money. We provide training and tech-support for all our clients because we believe that you deserve a real opportunity.
							</p> */}
							<h5 className={`text-center ${mainBannerTextColor}`}>
								My Commitment to You!
							</h5>
							<p>
								My goal is to change people's lives by providing the best services and opportunities to Tax Companies and Tax Preparers. We do business fairly and we don't keep your hard earned money. We provide training and tech-support for all our clients because we believe that you deserve a real opportunity.
							</p>
					</Container>
					<NavLink
						to="/contact"
						className={`mt-${space} btn btn-outline-warning`}
						style={{maxWidth: '300px', margin: 'auto'}}
					>
						Contact us
					</NavLink>
				</Row>
          	</Container>
        </div>
      </>
    );
};

export default AboutUs;
